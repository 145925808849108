import { Col, Container, Row } from "react-bootstrap";
import { ChevronLeft, XLg } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useLiff } from 'react-liff';
import { HeaderProps } from "../my-type";

export default function Header({ NavigatePath, HeaderTitle, OnReturn }: HeaderProps) {
  const { liff } = useLiff();
  const location = useLocation();


  return (
    <div style={{ backgroundColor: 'black', color: 'white', height: '50px' }}>
      <Row>
        <Col xs={2} style={{ textAlign: 'left' }}>
          <div style={{ paddingTop: '12px' }}>
            {location.pathname !== "/" && <ChevronLeft style={{ fontSize: '20px' }} onClick={OnReturn} />}
          </div>
        </Col>
        <Col><h6 style={{ paddingTop: '12px', fontSize: '18px' }}>{HeaderTitle}</h6></Col>
        <Col xs={2} style={{ textAlign: 'right' }}>
          {/* <div style={{ paddingTop: '5px' }}><XLg onClick={(e) => liff.closeWindow()} /></div> */}
        </Col>
      </Row>
    </div>
  )


}