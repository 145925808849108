import axios, { AxiosRequestConfig } from "axios"

export const bindLineAccount = async (accessToken: string) => {
  try {
    const axiosConfig: AxiosRequestConfig = {
      method: "GET",
      url: `/api/Auth/BindLineAccount`,
      params: { accessToken: accessToken },
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios(axiosConfig);

    console.log(data);

    return data;

  } catch (error) {
    console.log(error);
    return null;
  }
}