import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useLiff } from 'react-liff';
import { useNavigate } from "react-router-dom";
import { AirplaneFill, XLg } from "react-bootstrap-icons";
import { authUserData } from "../service";
import Header from "../layouts/Header";

export default function HomePage() {
  const { error, isLoggedIn, isReady, liff } = useLiff();
  const authUser = authUserData();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) return;

    // (async () => {
    //   const profile = await liff.getProfile();
    //   setDisplayName(profile.displayName);
    // })();
  }, [liff, isLoggedIn]);


  return (<>
    <Header NavigatePath="0" HeaderTitle="v0.1" OnReturn={() => { }} />
    <Row>
      <Col xs={2}></Col>
      <Col style={{ textAlign: 'left' }}>
        <div><br /><br /><h3>{authUser?.displayName} 您好!<br /><br />歡迎使用Ride Go</h3>
          <br />
          <AirplaneFill /><Button variant="link" style={{ textDecoration: 'none', color: 'black' }} onClick={() => navigate("/rider/order/create")} >預約機場接送</Button>
        </div>
      </Col>
      <Col xs={2}></Col>
    </Row>

  </>)
}