import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom"
import { bindLineAccount } from "../service/AuthService";
import { useLiff } from 'react-liff';
import { APIResult, User } from "../my-type";
import { useNavigate } from "react-router-dom";
import { actionCreators } from "../state";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";

export default function LineCallback() {
  const dispatch = useDispatch();
  const { updateUser } = bindActionCreators(actionCreators, dispatch);
  const [message, setMessage] = useState<string>('');
  const { isLoggedIn, liff } = useLiff();
  const navigate = useNavigate();

  useEffect(() => {

    const mapingAccount = async () => {

      const accessToken = liff.getAccessToken();
      console.log('getAccessToken', accessToken);
      if (accessToken) {
        //alert('取得accessToken');
        setMessage("登入中...");
        let callResultData: APIResult = await bindLineAccount(accessToken);
        console.log(callResultData);
        if (callResultData.statusCode === 0) {
          //alert(callResultData.message);
          setMessage("取得LINE身份資料時發生錯誤，請重新登入LINE");
        } else {
          //redirect to home
          //alert(callResultData);
          let user: User = callResultData.data;
          console.log(user);
          setMessage("登入成功，重新導向至首頁");
          updateUser(user);

          navigate("/");
        }

      }
    }

    if (isLoggedIn) {
      mapingAccount();
    }


  }, [isLoggedIn])


  return (<div className="App"><br></br><h3>{message}</h3>
  </div>)

} 