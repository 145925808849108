import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Container, Form, InputGroup, Modal, ModalBody, ProgressBar, Row, Tab, Tabs } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import { useLocation, useNavigate } from "react-router-dom";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { AirplaneFill, ArrowLeft, ArrowRight, CaretDownFill, CarFront, CarFrontFill, ChevronLeft, ChevronRight, DashCircle, Dot, Pen, PlusCircle, Search, XCircle, XLg } from "react-bootstrap-icons";
import { useLiff } from 'react-liff';
import { authUserData } from "../../service";
import Header from "../../layouts/Header";
import AddressSelect from "../../components/AddressSelect";


type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export default function CreateOrder() {
  const { error, isLoggedIn, isReady, liff } = useLiff();
  const [value, onChange] = useState<Value>(new Date());
  const [step, setStep] = useState<number>(0);
  const [flight, setFlight] = useState<string>("");
  const [orderType, setOrderType] = useState<number>(0);
  const [headerTitle, setHeaderTitle] = useState<string>('預約機場接送');
  const [address, setAddress] = useState<string | null>(null);
  const nevigate = useNavigate();
  const authUser = authUserData();
  const location = useLocation();


  useEffect(() => {
    //if (!isLoggedIn) return;

    // (async () => {
    //   const profile = await liff.getProfile();
    //   setDisplayName(profile.displayName);
    // })();

    switch (step) {
      case 0:
        setHeaderTitle("預約機場接送");
        break;
      case 1:
        setHeaderTitle("搭乘需求");
        break;
      case 2:
        setHeaderTitle("行李數量");
        break;
      case 3:
        setHeaderTitle("設定行程");
        break;
      case 4:
        setHeaderTitle("確認訂單");
        break;
      case 5:
        setHeaderTitle("旅客資訊");
        break;
      case 6:
        setHeaderTitle("搜尋地址");
        break;
      default:
        break;
    }

  }, [step]);


  const doNavigate = () => {
    switch (step) {
      case 0:
        nevigate("/");
        break;
      case 1:
        setStep(0);
        break;
      case 2:
        setStep(1);
        break;
      case 3:
        setStep(1);
        break;
      case 4:
        setStep(3);
        break;
      case 5:
        setStep(4);
        break;
      case 6:
        setStep(0);
        break;
      default:
        break;
    }
  }

  return (<div className="App">
    <Header NavigatePath="/" HeaderTitle={headerTitle} OnReturn={doNavigate} />
    {step === 0 && <>
      <div style={{
        paddingLeft: '15px', paddingRight: '15px'
      }}><div style={{ textAlign: 'left' }}><br /><h3>{authUser?.displayName}<br />您好！<br /></h3></div>
        <div><p></p></div>
        <div style={{ backgroundColor: 'white' }}>
          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
            fill
          >
            <Tab eventKey="home" title="送機">
              <Form style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                <Form.Group className="mb-3" style={{ textAlign: 'left' }} controlId="exampleForm.ControlInput1">
                  <Form.Label>上車地址</Form.Label>
                  <Form.Control placeholder="輸入上車地址" onFocus={() => setStep(6)} value={address!} />
                </Form.Group>
                <Form.Group className="mb-3" style={{ textAlign: 'left' }} controlId="exampleForm.ControlInput1">
                  <Form.Label>下車地址</Form.Label>
                  <Form.Select>
                    <option>選擇機場</option>
                    <option>桃園國際機場(TPE)第一航廈</option>
                    <option>桃園國際機場(TPE)第二航廈</option>
                    <option>台北松山機場(TSA)</option>
                    <option>台中國際機場(RMQ)</option>
                    <option>高雄國際機場(KHH)</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="d-grid" style={{ textAlign: 'left' }} controlId="exampleForm.ControlInput1">
                  <Form.Label>乘車時間</Form.Label>
                  <DateTimePicker onChange={onChange} value={value} />
                </Form.Group>
                <br></br>
                <Form.Group className="d-grid">
                  <Button variant="outline-secondary" onClick={() => {
                    setStep(1);
                  }}>選擇車種</Button>
                </Form.Group>
              </Form>
              <br></br>

            </Tab>
            <Tab eventKey="profile" title="接機">
              <Form style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                <Form.Group className="mb-3" style={{ textAlign: 'left' }} controlId="exampleForm.ControlInput1">
                  <Form.Label>上車地址</Form.Label>
                  <Form.Select>
                    <option>選擇機場</option>
                    <option>桃園國際機場(TPE)第一航廈</option>
                    <option>桃園國際機場(TPE)第二航廈</option>
                    <option>台北松山機場(TSA)</option>
                    <option>台中國際機場(RMQ)</option>
                    <option>高雄國際機場(KHH)</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" style={{ textAlign: 'left' }} controlId="exampleForm.ControlInput1">
                  <Form.Label>下車地址</Form.Label>
                  <Form.Control placeholder="輸入下車地址" />
                </Form.Group>

                <Form.Group className="d-grid" style={{ textAlign: 'left' }} controlId="exampleForm.ControlInput1">
                  <Form.Label>乘車時間</Form.Label>
                  <DateTimePicker onChange={onChange} value={value} />
                </Form.Group>
                <br></br>
                <Form.Group className="d-grid">
                  <Button variant="outline-secondary" onClick={() => {
                    setStep(1);
                  }}>選擇車種</Button>
                </Form.Group>
              </Form>
              <br></br>
            </Tab>

          </Tabs>
        </div></div></> //step 0 end.
    }

    {
      //搭乘需求
      step === 1 && <>
        <Container fluid="md" style={{ backgroundColor: 'white' }} className="rounded">
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}><h5>旅客人數</h5></Col>
          </Row>
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}>一般</Col>
            <Col xs={4}><DashCircle /> 1 <PlusCircle /></Col>
          </Row>
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}>4歲以下兒童</Col>
            <Col xs={4}><DashCircle /> 1 <PlusCircle /></Col>
          </Row>
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}>行李數</Col>
            <Col xs={4}>未填寫 <ChevronRight onClick={() => setStep(2)} /></Col>
          </Row>
          <br></br>
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}><h5>選擇車種</h5></Col>
          </Row>
          <br></br>
          <Row>
            <Col xs={2}><CarFront /></Col>
            <Col style={{ textAlign: 'left' }}>多元計程車</Col>
            {/* <Col xs={4} style={{ textAlign: 'left' }}>$1385</Col> */}
          </Row>
          <br></br>
          <Row>
            <Col xs={2}><CarFront /></Col>
            <Col style={{ textAlign: 'left' }}>小黃計程車</Col>
            {/* <Col xs={4} style={{ textAlign: 'left' }}>$1270~$1520</Col> */}
          </Row>
          <br></br>
          <Row>
            <Col xs={2}><CarFront /></Col>
            <Col style={{ textAlign: 'left' }}>機場四人專車</Col>
            {/* <Col xs={4} style={{ textAlign: 'left' }}>$1385</Col> */}
          </Row>
          <br></br>
          <Row>
            <Col xs={2}><CarFront /></Col>
            <Col style={{ textAlign: 'left' }}>機場六人專車</Col>
            {/* <Col xs={4} style={{ textAlign: 'left' }}>$1385</Col> */}
          </Row>
          <br></br>
          <Row>
            <Col xs={2}><CarFront /></Col>
            <Col style={{ textAlign: 'left' }}>機場商務專車</Col>
            {/* <Col xs={4} style={{ textAlign: 'left' }}>$1385</Col> */}
          </Row>
          <br></br>
          <Row>
            <Col style={{ textAlign: 'right', marginBottom: '15px' }}>
              <Button variant="dark" onClick={() => {
                setStep(3);
              }}>下一步</Button>
            </Col>
          </Row>

        </Container>


      </> // step 1 end.
    }

    {
      // 選擇行李數
      step === 2 && <>
        <Container fluid="md" style={{ backgroundColor: 'white' }} className="rounded">
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}><h5>行李數量</h5></Col>
          </Row>
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}>20吋以下行李</Col>
            <Col xs={4}><DashCircle /> 1 <PlusCircle /></Col>
          </Row>
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}>21~25吋行李</Col>
            <Col xs={4}><DashCircle /> 1 <PlusCircle /></Col>
          </Row>
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}>26~27吋行李</Col>
            <Col xs={4}><DashCircle /> 1 <PlusCircle /></Col>
          </Row>
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}>28~29吋行李</Col>
            <Col xs={4}><DashCircle /> 1 <PlusCircle /></Col>
          </Row>
          <br></br>
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}><h5>乘車備註</h5></Col>
          </Row>
          <Row>
            <Col><Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <Button variant="outline-dark" style={{ width: '45%' }}>全部重置</Button>&nbsp;&nbsp;
              <Button variant="dark" style={{ width: '45%' }}>套用</Button>
            </Col>
          </Row>
          <br></br>
        </Container>

      </>
    }


    {
      //設定行程
      step === 3 && <>
        <Container fluid="md" style={{ backgroundColor: 'white' }} className="rounded">
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}><h5>您的送機計劃</h5></Col>
          </Row>
          <br></br>
          <Row>
            <Col xs={1}><CarFrontFill></CarFrontFill></Col>
            <Col style={{ textAlign: 'left' }}>2024/12/25 05:30 乘車時間<br></br><span style={{ color: 'GrayText', fontSize: '12px' }}>台北市台北市松山區健康路55號</span></Col>
            <Col xs={2}><Pen></Pen></Col>
          </Row>
          <br></br>
          <Row>
            <Col xs={1}><CaretDownFill></CaretDownFill></Col>
            <Col style={{ textAlign: 'left' }}>2024/12/25 05:30 下車時間(預估)<br></br><span style={{ color: 'GrayText', fontSize: '12px' }}>桃園國際機場(TPE)第一航廈</span></Col>
            <Col xs={2}></Col>
          </Row>
          <br></br>
          <Row>
            <Col xs={1}><AirplaneFill></AirplaneFill></Col>
            {flight.length > 0 && <Col style={{ textAlign: 'left' }}>2024/12/25 8:30 起飛<br></br>
              <span style={{ color: 'GrayText', fontSize: '12px' }}>TTW200</span>
            </Col>}
            {flight.length === 0 && <Col style={{ textAlign: 'left' }}>
              <InputGroup className="mb-3">
                <Form.Control size="sm" />
                <InputGroup.Text><Search onClick={() => setFlight("TTW001")}></Search>
                </InputGroup.Text>

              </InputGroup>

            </Col>}
            <Col xs={2}></Col>
          </Row>
          <br></br>



        </Container>

        <Container fluid="md" style={{ backgroundColor: 'white', marginTop: '15px' }}>
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}><h5>車種及人數</h5></Col>
          </Row>
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}>機場六人專車．3乘客．1兒童<br></br>
              <span style={{ color: 'GrayText', fontSize: '12px' }}>21~25吋行李1件．26~27吋行李3件</span></Col>
            <Col xs={2}><CarFront></CarFront></Col>
          </Row>
          <br></br>
          <br></br>
          <Row>
            <Col style={{ textAlign: 'right', marginBottom: '15px' }}>
              <Button variant="dark" onClick={() => {
                setStep(4);
              }
              }>下一步</Button>
            </Col>
          </Row>
        </Container>

      </> // step 3 end.
    }

    {
      //確認訂單
      step === 4 && <>

        <Container fluid="md" style={{ backgroundColor: 'white' }} className="rounded">
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}><h5>旅客資訊</h5></Col>
          </Row>
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}>姓名</Col>
            <Col xs={4} style={{ textAlign: 'right', color: 'GrayText' }}>未填寫</Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'left' }}>電話</Col>
            <Col xs={4} style={{ textAlign: 'right', color: 'GrayText' }}>未填寫</Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'left' }}>身分證字號/外籍旅客護照</Col>
            <Col xs={4} style={{ textAlign: 'right', color: 'GrayText' }}>未填寫</Col>
          </Row>
          <br></br>
          <Row>
            <Col><div className="d-grid gap-2"><Button variant="dark" size="sm" onClick={() => setStep(5)}>新增資料</Button></div></Col>
          </Row>
          <br></br>
        </Container>

        <Container fluid="md" style={{ backgroundColor: 'white', marginTop: '15px' }} className="rounded">
          <br></br>
          <Row>
            <Col style={{ textAlign: 'left' }}><h6>2024/12/25 05:30</h6></Col>
            <Col xs={4} style={{ textAlign: 'right' }}><h5>送機</h5></Col>
          </Row>
          <br></br>
          <Row>
            <Col xs={1}><CarFrontFill></CarFrontFill></Col>
            <Col style={{ textAlign: 'left' }}>2024/12/25 05:30 乘車時間<br></br><span style={{ color: 'GrayText', fontSize: '12px' }}>台北市台北市松山區健康路55號</span></Col>
            <Col xs={2}></Col>
          </Row>

          <Row>
            <Col xs={1}><CaretDownFill></CaretDownFill></Col>
            <Col style={{ textAlign: 'left' }}>2024/12/25 05:30 下車時間(預估)<br></br><span style={{ color: 'GrayText', fontSize: '12px' }}>桃園國際機場(TPE)第一航廈</span></Col>
            <Col xs={2}></Col>
          </Row>
          <Row>
            <Col xs={1}><AirplaneFill></AirplaneFill></Col>
            <Col style={{ textAlign: 'left' }}>2024/12/25 8:30 起飛<br></br>
              <span style={{ color: 'GrayText', fontSize: '12px' }}>TTW200</span>
            </Col>
            <Col xs={2}></Col>
          </Row>
          <br></br>
          <Row>
            <Col style={{ textAlign: 'right', marginBottom: '15px' }}>
              <Button variant="dark">確認訂單</Button>
            </Col>
          </Row>
        </Container>

      </> // step 4 end.
    }

    {
      // 旅客資訊
      step === 5 && <>

        <Container fluid="md" style={{ backgroundColor: 'white' }} className="rounded">
          <br></br>
          <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
            <Row>
              <Col style={{ textAlign: 'left' }}><h6>為符合交通法規規定，旅客資料僅用於旅客投保</h6></Col>
            </Row>
          </div>
          <br></br>
          <Form style={{ paddingLeft: '15px', paddingRight: '15px' }}>
            <Form.Group className="mb-3" style={{ textAlign: 'left' }} controlId="exampleForm.ControlInput1">
              <Form.Label>護照姓名</Form.Label>
              <Form.Control placeholder="僅可輸入英文，如：Lai,Te-Shi" />
            </Form.Group>
            <Form.Group className="mb-3" style={{ textAlign: 'left' }} controlId="exampleForm.ControlInput1">
              <Form.Label>電話</Form.Label>
              <Form.Control value="+886913888257" />
            </Form.Group>
            <Form.Group className="mb-3" style={{ textAlign: 'left' }} controlId="exampleForm.ControlInput1">
              <Form.Label>身分證字號 / 外籍旅客護照號碼</Form.Label>
              <Form.Control placeholder="僅可輸入數字及英文" />
            </Form.Group>



            <br></br>
            <Form.Group className="d-grid">
              <Button variant="dark" onClick={() => setStep(4)}>儲存資料</Button>
            </Form.Group>
            <br></br>
          </Form>
        </Container>



      </>
    }

    {
      //搜尋地址
      step === 6 && <><AddressSelect onAddressSelect={(addr) => {
        console.log(addr);
        setAddress(addr);
      }
      } /></>
    }




    {/* <p><span style={{ color: 'grey', fontSize: '12px' }}>easyride.tw</span></p> */}

    <p></p>

  </div>)

}

