import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import CreateOrder from './pages/Order/Create';
import HomePage from './pages/Home';
//import { useLiff } from 'react-liff';
import LineCallback from './pages/LineCallback';
import Header from './layouts/Header';



function App() {
  // const { error, isLoggedIn, isReady, liff } = useLiff();

  // useEffect(() => {
  //   if (!isLoggedIn) return;

  //   (async () => {
  //     const profile = await liff.getProfile();
  //     setDisplayName(profile.displayName);
  //   })();
  // }, [liff, isLoggedIn]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/line/callback" element={<LineCallback />} />
        <Route path="/rider">
          <Route index element={<HomePage />} />
          <Route path='order/create' element={<CreateOrder />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
