import { LiffProvider } from 'react-liff';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from './state/store';
import { APIProvider } from '@vis.gl/react-google-maps';

const liffId = process.env.REACT_APP_LINE_LIFF_ID;
console.log(liffId);

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
console.log(API_KEY);

let persistor = persistStore(store);

//persistStore()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>

      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <APIProvider apiKey={API_KEY!}
            solutionChannel='GMP_devsite_samples_v3_rgmautocomplete'>
            <LiffProvider liffId={liffId!} withLoginOnExternalBrowser={true}>
              <App />
            </LiffProvider>
          </APIProvider>
        </PersistGate>
      </Provider>

    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
