import { ActionType } from "../action-types";
import { Action } from "../actions";
import { User } from "../../my-type";


const userReducer = (state: User | null = null, action: Action) => {
  switch (action.type) {
    case ActionType.UPDATE_USER:
      return (state = action.payload);
    default:
      return state;
  }
}

export default userReducer;