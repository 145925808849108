import { Col, Form, Row } from "react-bootstrap";
import { BuildingFill, Check, Dot, HouseFill } from "react-bootstrap-icons";

import { useEffect, useState } from "react";
import GoogleAddressSerach from "./GoogleAdressSerach";


interface AddressSelectProps {
  onAddressSelect: (address: string | null) => void;
}

export default function AddressSelect({ onAddressSelect }: AddressSelectProps) {
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);

  useEffect(() => {
    if (selectedPlace) {
      console.log(selectedPlace);
      onAddressSelect(selectedPlace.formatted_address!);
    }
  }, [selectedPlace])

  return (<div style={{ backgroundColor: 'white' }}>
    <div>
      <br></br>
      <Row>
        {/* <Col xs="1" style={{ textAlign: 'right', paddingLeft: '10px' }}><Dot style={{ fontSize: '32px' }} /></Col> */}
        <Col xs="1">{selectedPlace ? <Check style={{ fontSize: '32px' }} /> : <Dot style={{ fontSize: '32px' }} />}</Col>
        <Col style={{ textAlign: 'left' }}>
          <GoogleAddressSerach onPlaceSelect={setSelectedPlace} />
        </Col>

      </Row>
      <br></br>
      <Row>
        <Col xs="2" style={{ textAlign: 'right' }}><HouseFill style={{ fontSize: '24px' }} /></Col>
        <Col style={{ textAlign: 'left' }}><span>健康路55號</span><br />
          <span style={{ color: 'gray' }}>台北市松山區健康路55號</span>
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col xs="2" style={{ textAlign: 'right' }}><BuildingFill style={{ fontSize: '24px' }} /></Col>
        <Col style={{ textAlign: 'left' }}><span>健康路55號</span><br />
          <span style={{ color: 'gray' }}>台北市松山區健康路55號</span>
        </Col>
      </Row>
      <br></br>
    </div>
  </div>)
}