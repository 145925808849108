import { User } from "../my-type";
import { store } from '../state/index';

export const authUserData = () => {
  const authUser: User | null = store.getState().user;
  return authUser;

  // const authUser: User = {
  //   id: 1,
  //   userId: 'test1234',
  //   displayName: 'test',
  //   email: 'bevis.tw@gmail.com',
  //   roleString: 'User',
  //   jwtToken: 'xxxxxxx',
  //   refreshToken: 'xxxxxxx'
  // }

  // return authUser;
}